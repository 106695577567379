import React,{useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import * as SHelper from '../helpers/session_helper';
import './Zoom.css';
import { ZoomMtg } from '@zoomus/websdk';
import KJUR from 'jsrsasign'

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.11.5/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
//   const [roomNumber,setRoomNumber] = useState('85052608912');
//   const [roomPass,setRoomPass] = useState('uPr8tt');

  var sdkKey = 'iFtSrW6QjqLRQnhgEglQ';
  var clientSecret = 'gu9HAuZWladqK7KEWAASU5Mbf9qcKwbr';


  const { key } = useParams();
    // const [roomNumber, setRoomNumber] = useState('');
    // const [roomPass, setRoomPass] = useState('');
    const croomNumber = useRef('');
    const croomPass = useRef('');
    const cauthEndpoint = useRef('');
    const csdkKey = useRef('');
    const crole = useRef('');
    const cuserName = useRef('');
    const cuserEmail = useRef('');
    const cregistrantToken = useRef('');
    const czakToken = useRef('');
    const cleaveUrl = useRef('');

    useEffect(()=>{
        if(key){
            try {
                let decryptedKey = SHelper.decrypt(key);
                let jsonData = JSON.parse(decryptedKey);
                croomNumber.current=jsonData.roomNumber
                croomPass.current=jsonData.roomPass
                cauthEndpoint.current=jsonData.authEndpoint
                csdkKey.current=jsonData.sdkKey
                crole.current=jsonData.role
                cuserName.current=jsonData.userName
                cuserEmail.current=jsonData.userEmail
                cregistrantToken.current=jsonData.registrantToken
                czakToken.current=jsonData.zakToken
                cleaveUrl.current=jsonData.leaveUrl
            } catch (error) {
                
            }
        }
    },[key])


  function getSignature(e) {
    if(e)e.preventDefault();

    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2

    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      sdkKey: sdkKey,
      mn: croomNumber.current,
      role: crole.current,
      iat: iat,
      exp: exp,
      appKey: csdkKey.current,
      tokenExp: iat + 60 * 60 * 2
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, clientSecret)
    startMeeting(signature)
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: cleaveUrl.current,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: csdkKey.current,
          meetingNumber: croomNumber.current,
          passWord: croomPass.current,
          userName: cuserName.current,
          userEmail: cuserEmail.current,
          tk: cregistrantToken.current,
          zak: czakToken.current,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <>
    <div className="container">
      <h1>Join Meeting</h1>
      <button className="join-button" type="button" onClick={getSignature}>Let's Join</button>
    </div>
    </>
  );
}

export default App;
