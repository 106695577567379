import React,{useState} from 'react';

import './App.css';
import { ZoomMtg } from '@zoomus/websdk';
import KJUR from 'jsrsasign'

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.11.5/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
  const [roomNumber,setRoomNumber] = useState('85052608912');
  const [roomPass,setRoomPass] = useState('uPr8tt');

  var sdkKey = 'iFtSrW6QjqLRQnhgEglQ';
  var clientSecret = 'gu9HAuZWladqK7KEWAASU5Mbf9qcKwbr';
  var role = 0
  var userName = 'React'
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = 'http://localhost:3002'

  function getSignature(e) {
    e.preventDefault();

    const iat = Math.round(new Date().getTime() / 1000) - 30;
    const exp = iat + 60 * 60 * 2

    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      sdkKey: sdkKey,
      mn: roomNumber,
      role: role,
      iat: iat,
      exp: exp,
      appKey: sdkKey,
      tokenExp: iat + 60 * 60 * 2
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, clientSecret)
    startMeeting(signature)
  }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: roomNumber,
          passWord: roomPass,
          userName: userName,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  return (
    <div className="App">
        <h2 className="meeting-header">Join Meeting</h2>
        <div className="meeting-container">
          <div>
            <label for="meetingid">Meeting Number</label>
            <input
              type="text"
              id="meetingid"
              placeholder="Meeting Number"
              value={roomNumber}
              onChange={(e)=>setRoomNumber(e.target.value)}
            />
          </div>
          <div>
            <label for="passcode">Passcode</label>
            <input
              type="text"
              placeholder="Passcode"
              value={roomPass}
              onChange={(e)=>setRoomPass(e.target.value)}
            />
          </div>
        </div>
        <div className="action-continer">
          <button
            className="join-meeting-button"
            onClick={getSignature}
          >
            Join Meeting
          </button>
        </div>
      </div>
  );
}

export default App;
