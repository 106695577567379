const CryptoJS = require("crypto-js");

export const encrypt= (data) => {
    if(data){
        return  CryptoJS.DES.encrypt(data, CryptoJS.enc.Utf8.parse('@Shik-SE'),{ iv: { words: [ 0, 0, 0, 0 ], sigBytes: 16 } }).toString();
    }
    else{
        return null;
    }
}

export const decrypt= (data) => {
    if(data){
        const bytes= CryptoJS.DES.decrypt(data, CryptoJS.enc.Utf8.parse('@Shik-SE'),{ iv: { words: [ 0, 0, 0, 0 ], sigBytes: 16 } });
        return bytes.toString(CryptoJS.enc.Utf8);
    }
    else{
        return null;
    }
    
}