import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage  from './pages/Home'
import ZoomPage  from './pages/Zoom'

function App() {

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:key" element={<ZoomPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
